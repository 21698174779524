import React from "react"
import styled from "@emotion/styled"
import TypographyHelper from "@seamusleahy/react-typography-helper"

const Wrapper = styled.article`
  margin: 0 auto 3em;
  padding: 0 5%;

  font-size: 19px;
`

const Header = styled.header`
  text-align: center;
  margin: auto;

  h1 {
    font-size: 75px;
    line-height: 1.2;
    letter-spacing: -0.025em;
    max-width: 11em;
    margin: auto;

    .underline {
        /* background: linear-gradient(transparent 70%, #f4e7e5 70%, #f4e7e5 90%, transparent 90%); */
    }

    .widont {
        white-space: nowrap;
    }

    .amp {
        font-size: 1.2em;
        margin: 0 -0.05em;
        line-height: 0.8;
        vertical-align: -0.1em;
    }
  }
`

const Prose = styled.div`
  line-height: 1.35;
  max-width: 35em;
  margin: auto;

  ul {
    margin: 0;
    padding-left: 0;

    li {
      display: block;
    }

    li::marker {
      color: #aea590;
    }
  }

  ol {
    margin: 0;
    padding-left: 1.5em;
    counter-reset: ol-counter;

    li {
      margin-bottom: 0.75em;
      display: block;
      position: relative;
    }

    li::before {
      content: counter(ol-counter, decimal) ".";
      counter-increment: ol-counter;
      display: block;
      position: absolute;
      left: -1em;
      top: 0;
      font-size: 1.5em;
      color: #514528;
    }
  }

  h2 {
    font-size: 2em;
    margin: 1em 0 0.25em -0.1em;
    letter-spacing: -0.025em;
    color: #1e1601;
  }

  h3 {
    font-size: 1em;
    font-weight: bold;
    margin: 0.75em 0 0.2em -0.1em;
    letter-spacing: -0.025em;
  }
`

export interface ArticleProps {
  title: string
}

const Article: React.FunctionComponent<ArticleProps> = ({
  title,
  children,
}) => (
  <Wrapper>
    <Header>
      <h1>
        <span className="underline"><TypographyHelper
          text={title}
          wrapWidont
          smartEllipsis
          smartDashes
          smartQuotes
          wrapAmpersand
          updateOnlyWhenTextChanges={false}
        /></span>
      </h1>
    </Header>
    <Prose>{children}</Prose>
  </Wrapper>
)

export default Article
