import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { RecipeBySlugQuery, MarkdownRemarkFilterInput } from "../__generated__/gatsby-types"
import Article from "../components/Article"

// The pageContext is created in gatsby-node.js
export interface PageContext {
  slug: string
  previous?: MarkdownRemarkFilterInput 
  next?: MarkdownRemarkFilterInput
}

export interface RecipeTemplateParams {
  data: RecipeBySlugQuery
  pageContext: PageContext
}

const RecipeTemplate: React.FunctionComponent<RecipeTemplateParams> = ({ data, pageContext }) => {
  const recipe = data.markdownRemark
  if (
    !data.site?.siteMetadata?.title ||
    !recipe ||
    !recipe?.frontmatter ||
    !recipe?.frontmatter?.title ||
    !recipe?.html
  ) {
    throw new Error(`Missing data for the blog post: {data?.markdownRemark?.id}`)
  }
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  function insertFractionalCharacters(html: string) {
    return html
      .replace(/\b1\/2\b/g, `&frac12;`)
      .replace(/\b([12])\/3\b/g, (_, numerator) => `&frac${numerator}3;`)
      .replace(/\b([13])\/4\b/g, (_, numerator) => `&frac${numerator}4;`)
      .replace(/\b([1357])\/4\b/g, (_, numerator) => `&frac${numerator}8;`)
  }

  const htmlContents = insertFractionalCharacters(recipe.html);

  return (
    <Layout title={siteTitle}>
      <SEO
        title={recipe.frontmatter.title}
        description={recipe.frontmatter.description || recipe.excerpt}
      />
      <Article title={recipe.frontmatter.title}>
        <div dangerouslySetInnerHTML={{ __html: htmlContents }} />
      </Article>
    </Layout>
  )
}

export default RecipeTemplate

export const pageQuery = graphql`
  query RecipeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
